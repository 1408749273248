@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Taviraj:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --font-family-default: 'Roboto', sans-serif;

  --clr-background-1: 0, 0%, 100%;
  --clr-background-2: 210, 21%, 89%;
  --clr-background-3: 210, 60%, 43%; /*  mid-blue */
  --clr-primary: 209, 100%, 25%; /* dark-blue */
  --clr-secondary: 205, 25%, 80%; /* light-blue */
  --clr-stat: 210, 44%, 63%; /* stat-blue */
  --clr-logo-grey: 232, 13%, 55%;
  --clr-white: 0, 0%, 100%;
  --clr-gold: 37, 100%, 62%;
  --clr-burgundy: #861657;
  --clr-brick: #af253c;
  --clr-pink: #EF767A;


  --fs-100: .5rem;
  --fs-200: .875rem;
  --fs-300: 1rem;
  --fs-400: 1.125rem;
  --fs-500: 1.25rem;
  --fs-600: 1.5rem;
  --fs-700: 1.75rem;
  --fs-800: 2.5rem;
  --fs-900: 6rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
}

body { 
  position: relative;
  overscroll-behavior: none;
  background-color: hsl(var(--clr-white));
  color: hsl(var(--clr-primary));
  font-family: var(--font-family-default);
  font-size: var(--fs-400);
  font-weight: 400;
}

section {
  position: relative;
  width: 100%;
  padding: 2rem 0;
}

.section-header {
  margin-bottom: 2rem;
  font-size: var(--fs-800);
  font-weight: 600;
  min-width: 100px;
}

.section-subhead {
  margin: 3rem 0 1rem;
  font-size: var(--fs-700);
  font-weight: 600;
  max-width: 700px;
}

.programs-section-background > * {
  position: relative;
  z-index: 2;
}

.back-btn {
  position: relative;
  display: flex;
  align-items: center;
  align-self: flex-start;
  position: fixed;
  top: 10.4rem;
  left: 2rem;
  cursor: pointer;
  z-index: 20;
  transition: all .2s ease-in-out;
}

.back-btn:hover {
  scale: 1.04;
}

.back-btn-icon {
  font-size: 1.7rem;
}

.single-page-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 10rem;
}

.single-page-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1400px;
}

.single-page-header {
  position: relative;
  align-self: flex-start;
  z-index: 1;
}

.unordered-list {
  list-style: disc;
  padding-left: 1rem;
}

.order-list {
  padding-left: 1.2rem;
  list-style: decimal;
}

.copy {
  line-height: 1.3;
  max-width: 900px;
  margin: 0 0 1rem 0 ;
}

.text-right {
  max-width: 100%;
  text-align: right;
}

.text-left {
  max-width: 100%;
  text-align: left;
}

.text-center {
  max-width: 100%;
  text-align: center;
}

.copy-bold {
  font-weight: 600;
}

.copy-thin {
  font-weight: 200;
}

.copy-bottom {
  margin-bottom: 7rem;
}

.noscroll { 
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  position:fixed;
}

.block {
  font-size: 1.5rem;
  font-weight: 500;
  font-style: italic;
  padding: 1.5rem;
  color: hsl(var(--clr-white));
  border-radius: .4rem;
  margin: 1rem 0 2rem;
  box-shadow: 6px 6px 10px hsl(var(--clr-primary), .4);
}

.btn-hover:hover {
  transform: scale(.98);
  box-shadow: inset 3px 3px 10px hsl(var(--clr-background-3), .4);
}

.underline {
  text-decoration: underline;
  text-underline-offset: 7px;
  text-decoration-thickness: 2px;
}

.italicize {
  font-style: italic;
}

.phone-copy-underline {
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 1.5px;
}

.link-blue-text {
  font-weight: 500;
  padding-bottom: 2px;
  color: hsl(var(--clr-primary));
  background-image: linear-gradient(hsl(var(--clr-primary)), hsl(var(--clr-primary)));
  background-position: 0 100%;
  background-size: 0% 1.5px;
  background-repeat: no-repeat;
  transition:
    background-size 0.2s,
    background-position 0s 0.2s;
  cursor: pointer;
}

.link-blue-text:hover {
  color: hsl(var(--clr-primary));
  background-position: 100% 100%;
  background-size: 100% 1.5px;
}

.link-white-text {
  padding-bottom: 2px;
  font-weight: 500;
  color: hsl(var(--clr-white));
  background-image: linear-gradient(hsl(var(--clr-white)), hsl(var(--clr-white)));
  background-position: 0 100%;
  background-size: 0% 1.25px;
  background-repeat: no-repeat;
  transition:
    background-size 0.2s,
    background-position 0s 0.2s;
  cursor: pointer;
}

.link-white-text:hover {
  color: hsl(var(--clr-white));
  background-position: 100% 100%;
  background-size: 100% 1.25px;
}

.link-red-text {
  padding-bottom: 2px;
  font-weight: 500;
  color: var(--clr-brick);
  background-image: linear-gradient(var(--clr-brick), var(--clr-brick));
  background-position: 0 100%;
  background-size: 0% 1.25px;
  background-repeat: no-repeat;
  transition:
    background-size 0.2s,
    background-position 0s 0.2s;
  cursor: pointer;
}

.link-red-text:hover {
  color: var(--clr-brick);
  background-position: 100% 100%;
  background-size: 100% 1.25px;
}

.navy-background {
  background-color: hsl(var(--clr-primary), .8);
}

.burgundy-background {
  background-color: #861657;
}

.burgundy-gradient {
  background-image:
    radial-gradient(
       circle closest-side,
      #d54499,
      #861657
    );
}

.burgundy-linear-gradient {
  background-image:
    linear-gradient(
      to bottom right,
      #d54499,
      #861657,
      #861657
    );
}

.brick-background {
  background-color: #af253c;
}

.brick-gradient {
  background-image:
    radial-gradient(
       circle closest-side,
      #e86b80,
      #af253c
    );
}

.brick-linear-gradient {
  background-image:
    linear-gradient(
      to bottom right,
      #e86b80,
      #af253c,
      #af253c
    );
}

.pink-background {
  background-color: var(--clr-pink);
}

.pink-gradient {
  background-image:
    radial-gradient(
       circle closest-side,
      #f7d0d2,
      var(--clr-pink)
    );
}

.pink-linear-gradient {
  background-image:
    linear-gradient(
      to bottom right,
      #f7d0d2,
      var(--clr-pink),
      var(--clr-pink)
    );
}

.lightblue-gradient {
  background-image:
  linear-gradient(
    to right,
  hsl(0, 0%, 98%),
  hsl(208, 27%, 90%),
  hsl(var(--clr-secondary))
);
}

.darkblue-linear-gradient {
  background-image:
  linear-gradient(
    to bottom right,
    hsl(var(--clr-background-2)),
    hsl(var(--clr-primary))
  );
}

.position-left {
  align-self: flex-start;
}

.position-right {
  align-self: flex-end;
}

.position-center {
  align-self: center;
}

.cta-btn {
  background-color: hsl(var(--clr-primary));
  color: hsl(var(--clr-white));
  border: none;
  border-radius: .2rem;
  padding: 1rem 2rem;
  font-weight: 500;
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.program-btn {
  width: 250px;
  background-color: hsl(var(--clr-primary));
  color: hsl(var(--clr-white));
  border-radius: .2rem;
  padding: 1rem 2rem;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  z-index: 10;
}

.contact-link {
  color: hsl(var(--clr-primary));
  text-decoration: underline;
  text-underline-offset: .2rem;
}

.contact-link:hover {
  color: #861657;
}

.master-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* INTRO SECTION */

.intro-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image:
    linear-gradient(
      to right,
    hsl(0, 0%, 98%),
    hsl(208, 27%, 90%),
    hsl(var(--clr-secondary))
  );
}

.intro-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.intro-header {
  position: absolute;
  top: 25%;
  left: 31%;
  color: hsl(var(--clr-white));
  font-size: 7rem;
  font-weight: 600;
  text-shadow: 3px 3px 4px hsl(var(--clr-primary), 0.6);
  z-index: 2;
}

.intro-copy-container {
  position: absolute;
  top: 65%;
  left: 60%;
  display: flex;
  align-items: center;
  width: 50%;
  text-shadow: 2px 2px 4px #ffffff;
}
/* 
.intro-line-graphic {
  background-color: hsl(var(--clr-primary));
  height: 2px;
  width: 30rem;
} */

.intro-copy {
  margin-left: 0rem;
  font-size: 1.2rem;
  line-height: 1.2;
  max-width: 24rem;
}

.intro-cta {
  position: absolute;
  left: 2rem;
  bottom: 40px;
  background-color: hsl(var(--clr-white));
  color:hsl(var(--clr-primary));
  box-shadow: -2px 2px 5px hsl(var(--clr-white), .1);
  font-size: 1.3rem;
  font-weight: 400;
  z-index: 2;
  animation: all .1s ease-in-out;
}

.intro-cta:hover {
  background-color: hsl(var(--clr-primary));
  color:hsl(var(--clr-white));
}

/* QUOTE BANNER */

.quote-banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 250px;
  padding: 1.5rem 0rem;
  font-size: 1.7rem;
  font-weight: 300;
  font-style: italic;
  text-align: center;
  line-height: 1.3;
  color: hsl(var(--clr-white));
}

.quote-background {
  max-width: 1000px;
}

.quote-banner span {
  padding-right: 0rem;
  color: hsl(var(--clr-primary));
  font-family: 'Taviraj', serif;
  font-size: 4.2rem;
  font-weight: 600;
  text-decoration:underline;
  text-underline-offset: 6px;
  text-decoration-thickness: 1.5px;
  line-height: 1;
}

/* PHONE SECTION  */
.phone-section {
  display: flex;
  justify-content: center;
  padding: 3rem;
}

.phone-copy-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 250px;
  max-width: 500px;
  padding: 2rem 1rem;
  margin: 0 2rem;
  border: 2px solid hsl(var(--clr-primary));
  background-color: hsl(var(--clr-white));
  box-shadow: 5px 5px 20px #5a585877;
}

.phone-btn {
  display: inline-block;
  width: 100%;
  background-color: hsl(var(--clr-primary));
  color: hsl(var(--clr-white));
  border: none;
  padding: 1.3rem 1rem;
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.2;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.phone-btn.mobile {
  display: none;
}

.phone-btn:hover {
  background-color: hsl(var(--clr-secondary));
  color: hsl(var(--clr-primary));
}


/* APPROACH SECTION */

.approach-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7rem 0 6rem;
}

.approach-header {
  color: hsl(var(--clr-primary))
}

.approach-content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 12rem);
  height: 100%;
}

.approach-graphic {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 3rem;
}

.approach-team {
  position: relative;
  margin: 3rem 1rem;
}

.approach-background {
  display: flex;
  justify-content: center;
  max-width: 380px;
  align-items: center;
  height: 250px;
  color: hsl(var(--clr-white));
  box-shadow: 2px 2px 15px #5a5858b5;
}

.approach-highlight {
  padding: 1rem 5rem;
  margin: 2rem 0 8rem;
  max-width: 1100px;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  border-top: 2px solid hsl(var(--clr-primary));
  border-bottom: 2px solid hsl(var(--clr-primary));
}

.approach-content-bottom {
  padding: 4rem 10rem;
  width: 100%
}

.approach-content-container {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(10 auto);
  grid-template-rows: repeat(6 auto);
}

.approach-copy.last {
  margin-bottom: 2rem;
}

.approach-copy-bottom {
  margin-bottom: 0;
}

.approach-img-container-main {
  position: relative;
  grid-column: 1 / 8;
  grid-row: 1 / 7;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.approach-copy-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: 8 / 11;
  grid-row: 1 / 4;
  padding: 1.5rem;
  background-color: hsl(var(--clr-white), .9);
  color: var(--clr-pink);
  font-size: 1.4rem;
  font-weight: 300;
  border-radius: .2rem;
}

.approach-copy-container-mobile {
  display: none;
}

.approach-mom-img {
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  grid-column: 8 / 11;
  grid-row: 4 / 7;
  border-radius: .2rem;
  object-fit: cover;
}

.approach-family-img {
  object-fit: cover;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  border-radius: .2rem;
  margin-bottom: 0;
  display: block;
}

.approach-copy-quote {
  position: absolute;
  bottom: 120px;
  left: 0;
  padding: 3.5rem 3rem 2rem;
  background-color: hsl(var(--clr-white), .9);
  color: var(--clr-pink);
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.2;
}

.approach-quote-left {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 2.2rem;
  margin-right: .4rem;
}

.approach-quote-right {
  position: absolute;
  font-size: 2.2rem;
  bottom: 2rem;
  right: 2rem;
}

.team-img {
  height: 300px;
  z-index: 10;
}

.team-copy {
  max-width: 330px;
  font-size: 1.7rem;
  padding: 1rem;
  font-weight: 300;
  z-index: 9;
}

/* PROGRAMS HOME SECTION */

.what-we-do-section {
  padding-top: 0;
  padding-bottom: 0;
}

.programs-section-background {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem 0 3rem;
}

.intro-content-container {
  position: relative;
  padding-top: 10rem;
  z-index: 1;
}

#social-workers,
#mentor-parents,
#corridor {
  position: absolute;
  top: -10rem;
}

.programs-header {
  max-width: 700px;
}

.prevention-header {
  max-width: 700px;
  padding: 2rem;
  line-height: 1.1;
  border-radius: .2rem;
}

.prevention-services-content {
  display: flex;
  width: 100%;
}

.prevention-services-content > div {
  padding: 1.5rem;
  margin-bottom: 2rem;
  background-image: 
  linear-gradient(
  to bottom right,
  hsl(var(--clr-white), .6),
  hsl(var(--clr-white), .7),
  hsl(var(--clr-white), .9),
  hsl(var(--clr-white), 1)
  );
  color: var(--clr-brick);
  border-radius: .2rem;
}

.prevention-services-content > * {
  line-height: 1.3;
}

.prevention-services-content > div:first-of-type {
  margin-right: 2rem;
  min-width: 315px;
  background-image: 
  linear-gradient(
    to bottom right,
    hsl(var(--clr-white), .5),
    hsl(var(--clr-white), .7),
    hsl(var(--clr-white), .9),
    hsl(var(--clr-white), 1)
  );
}

.prevention-services-content-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  color: hsl(var(--clr-white));
  background-color: #af253cbb;
  box-shadow: 4px 4px 15px hsl(var(--clr-white), .2);
  font-weight: 500;
  border-radius: .2rem;
  padding: 1.5rem;
  border-radius: .2rem;
}

.prevention-copy {
  max-width: 700px;
  line-height: 1.3;
  margin-bottom: 1rem;
}

.prevention-span {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.corridor-icon {
  margin-right: .5rem;
}

.prevention-link .link-white-text {
  font-weight: 300;
  margin-bottom: .4rem;
}
 
.prevention.section-subhead {
  margin: -2rem 0 3rem;
}

.prevention-subhead {
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: .7px;
}

.prevention-services-subhead {
  margin-bottom: .5rem;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: underline;
  text-align: center;
  text-underline-offset: 3px;
}

.mentor-section {
  position: relative;
  color: hsl(var(--clr-white));
  z-index: 1;
}

.programs-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 4rem;
  margin-bottom: 6rem;
  background-color: hsl(var(--clr-gold), .3);
}

.programs-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  margin: 3rem 2rem;
}

.program-content-container {
  align-self: center;
  position: relative;
  max-width: 1000px;
  width: 100%;
}

.program-content-container .copy { 
  max-width: 1000px;
}

.program-container {
  display: flex;
  margin-bottom: 2rem;
}

.programs-img {
  width: 350px;
  height: 300px;
  object-fit: cover;
  margin-bottom: .5rem;
  border-radius: .2rem;
}

.img-float-left {
  float: left;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.img-float-right {
  float: right;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.programs.section-subhead {
    margin: 3rem 0 1rem;
    padding-bottom: .2rem;
    font-size: 2rem;
    font-weight: 500;
    max-width: 100%;
    text-underline-offset: 5px;
    border-bottom: 1.5px solid hsl(var(--clr-primary));
}

.programs.section-subhead-white {
  margin: 3rem 0 1rem;
  padding-bottom: .2rem;
  font-size: 2rem;
  font-weight: 500;
  max-width: 100%;
  text-underline-offset: 5px;
  border-bottom: 1.5px solid hsl(var(--clr-white));
}

.subhead-container {
  display: flex;
  width: 100%;
}

.programs-subhead {
  max-width: 700px;
  padding: 2rem;
  margin-top: 2rem;
  font-weight: 400;
  color: hsl(var(--clr-white));
  line-height: 1.1;
  border-radius: .2rem;
  text-align: center;
  font-size: var(--fs-700);
}

.programs-copy {  
  max-width: 35rem;
  transition: all .3s ease-in-out;
  background-color: hsl(var(--clr-white), .7);
}

.programs-copy.copy {
  margin-bottom: 0;
}

.social-worker-section {
  position: relative;
  padding: 2rem 0 6rem;
  color: hsl(var(--clr-white));
  z-index: 1;
}

.corridor-section .programs.section-subhead,
.social-worker-section .programs.section-subhead, 
.mentor-section .programs.section-subhead{
  border-bottom: 1.5px solid hsl(var(--clr-white));
}

.first-call-section {
  width: 100%;
}

.first-call-videos-container {
  margin-bottom: 2rem;
}

.first-call-videos-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  max-width: 550px;
  height: 100%;
  margin-top: 1rem;
}

.first-call-video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 550px;
  height: 100%;
  padding: .5rem;
  border-radius: .2rem;
  border-bottom-right-radius: 0;
  background-color: hsl(var(--clr-white));
  color: hsl(var(--clr-primary));
}

.video-file {
  width: 100%;
  border-radius: .2rem;
}

.first-call-video .tab {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 1rem;
  padding: 1rem;
  font-size: .9rem;
  background-color: hsl(var(--clr-white), .4);
  color: var(--clr-primary);
  border-bottom-left-radius: .2rem;
  border-bottom-right-radius: .2rem;
  cursor: pointer;
}

.first-call-video .tab-1 {
  bottom: -2rem;
  right: 0;
}

.first-call-video .tab-2 {
  bottom: -2rem;
  right: 7.1rem;
}

.first-call-video .tab-3 {
  bottom: -2rem;
  right: 14.2rem;
}

.first-call-video .tab.active {
  background-color: hsl(var(--clr-white));
}

.first-call-hours {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  padding: 2rem;
  margin: 2rem auto;
  background-image: 
  linear-gradient(
  to bottom right,
  hsl(var(--clr-white), .3),
  hsl(var(--clr-white), .8),
  hsl(var(--clr-white), 1),
  hsl(var(--clr-white), .7)
  );
  color: hsl(var(--clr-primary));
  font-weight: 500;
  border-radius: .2rem;
}

.first-call-hours-list {
  text-align: center;
}

.first-call-hours-list .link-blue-text {
  font-weight: 300;
}

.first-call-hours-list li:first-child {
  margin-bottom: .5rem;
  font-size: 1.2rem;
  font-weight: 300;
}

.first-call-phone {
  font-size: 18px;
}

.first-call-disclaimer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.first-call-copy.copy {
  max-width: 700px;
}

.corridor-img-container {
  position: relative;
  z-index: 100;
}

.corridor-section {
  position: relative;
  color: hsl(var(--clr-white));
  z-index: 1;
}

.viet-video {
  display: block;
}

.corridor-subheader {
  margin-top: 2rem;
  max-width: 100%;
  color: hsl(var(--clr-primary));
  border-bottom: 1.5px solid hsl(var(--clr-primary));
}

.corridor.section-subhead {
  margin-top: 2rem;
  max-width: 100%;
  border-bottom: 1.5px solid hsl(var(--clr-white));
}

.corridor-subhead-container {
  display: flex;
  justify-content: center;
  margin: 2rem 0 1rem;
}

.corridor-subhead {
  position: relative;
  align-self: center;
  max-width: 650px;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: 1.4rem;
  font-style: italic;
  background-color: #af253cbb;
  box-shadow: 4px 4px 15px hsl(var(--clr-white), .2);
  color: hsl(var(--clr-white));
  text-align: center;
  line-height: 1.2;
  border-radius: .2rem;
  z-index: 1;
}

.corridor-quote-left {
  position: absolute;
  left: 1rem;
  top: 1rem;
  font-size: 1.4rem;
}
.corridor-quote-right {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  font-size: 1.4rem;
} 

/* RIGHTS HOME BANNER */

.rights-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
}

.rights-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  min-width: 1100px;
  padding: 1rem;
  font-size: 1.8rem;
  border-top: 2px solid hsl(var(--clr-primary));
  border-bottom: 2px solid hsl(var(--clr-primary));
  font-weight: 500;
}

.rights-banner > * {
  margin: 0 4rem;
  white-space: nowrap;
}

.rights-header {
  color: hsl(var(--clr-primary));
  font-weight: 600;
}

.rights-cta {
  background-color: hsl(var(--clr-primary));
  color: hsl(var(--clr-white));
  border: none;
  padding: 1rem 1.5rem;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.rights-cta:hover {
  background-color: hsl(var(--clr-secondary));
  color: hsl(var(--clr-primary));
}

/* NEWS HOMEPAGE SECTION */

.news-section {
  display: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 6rem;
}

.news-header {
  margin-bottom: 0;
}

.news-carousel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.carousel-arrow {
  font-size: 2.5rem;
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.carousel-arrow:hover {
  color: hsl(var(--clr-primary), .7);
}

.carousel-items-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 600px;
  margin: 5rem 1rem 1rem 0;
  overflow: hidden;
}

.carousel-item {
  display: flex;
  flex-direction: column;
  min-width: 26vw;
  height: 550px;
  padding: 0 1.5rem;
  text-align: left;
  font-size: 1rem;
  transition: .5s ease-in-out;
}

.carousel-item > * {
  margin-bottom: 1rem;
  color: hsl(var(--clr-primary));
}

.carousel-item-img {
  width: 100%;
  height: 20vw;
  object-fit: cover;
  box-shadow: 2px 2px 15px #7e7c7c98;
}

.carousel-item-header {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 1rem;
}

.carousel-item-date {
  font-size: .8rem;
  font-weight: 200;
  margin: .3rem 0 1rem;
}

.carousel-item-summary {
  line-height: 1.3;
}

.carousel-item-readmore {
  display: flex;
  margin-top: auto;
  margin-bottom: 0;
  text-align: right;
  justify-content: flex-end;
}

.carousel-item-readmore p {
  margin-right: .5rem;
}



.news-footer {
  display: flex;
  justify-content: flex-start;
  margin-top: auto;
  padding: 0 4rem;
}

.news-social-links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.news-social-copy {
  font-size: 1.1rem;
  font-weight: 300;
}

.news-social-icons {
  font-size: 1.6rem;
  color: hsl(var(--clr-primary));
  margin-left: .4rem;
  transition: all .2s ease-in-out;
}

.news-social-icons:hover {
  color: hsl(var(--clr-primary), .7);
}

.news-viewall-btn {
  min-width: 90px;
  background-color: hsl(var(--clr-primary));
  padding: .6rem 1rem;
  border: none;
  border-radius: .2rem;
  font-size: 1rem;
  color: hsl(var(--clr-white));
  transition: all .3s ease-in-out;
}

.news-viewall-btn:hover {
  min-width: 90px;
  background-color: hsl(var(--clr-secondary));
  color: hsl(var(--clr-primary));
}

/* NEWS ALL ARTICLES PAGE*/

.articles-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.articles-container {
  margin-bottom: 7rem;
}

.highlight {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 200px;
  width: 200px;
  padding: 1rem;
  margin: 0 0 1.5rem 1.5rem;
  box-shadow: 10px 10px 10px hsl(var(--clr-primary), .2);
  color: hsl(var(--clr-white));
  font-size: 1.7rem;
  transition: all .3s ease-in-out;
  text-shadow: 4px 2px 4px #ffffff51;
}

.highlight:hover {
  transform: scale(1.05);
}

.article-container {
  display: flex;
  max-width: 700px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  background-color: hsl(var(--clr-white));
  border: 2px solid hsl(var(--clr-primary));
  transition: all .2s ease-in-out;
}

.article-container:hover {
  transform: scale(1.02);
}

.article-container .article-img {
  position: relative;
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: .2rem;
}

.article-title {
  font-size: 1.6rem;
  font-weight: 400;
}

.article-date {
  margin: .2rem 0 1.5rem;
  font-size: .9rem;
  font-weight: 200;
}

.article-copy {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 250px;
  margin-left: 2rem;
  color: hsl(var(--clr-primary));
  border-radius: .2rem;
  font-size: .9rem;
  line-height: 1.2;
}

.article-body {
  max-width: 350px;
}

.article-video {
  margin-bottom: 1rem;
}

.article-image {
  margin-bottom: 1rem;
  border-radius: .2rem;
}

.second-article-image {
  margin-bottom: 1rem;
  border-radius: .2rem;
}

.article-readmore {
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  margin-top: auto;
  width: 90px;
  color: hsl(var(--clr-primary));
}

/* NEWS INDIVIDUAL ARTICLE PAGE*/

.article-detail-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  padding: 0 2rem;
}

.article-detail-content {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
}

.article-detail-content img {
  margin-left: auto;
  height: 350px;
  width: 450px;
  object-fit: cover;
  border-radius: .2rem;
  box-shadow: 10px 10px 10px hsl(var(--clr-primary), .3);
}

.article-detail-header {
  position: absolute;
  min-width: 500px;
  max-width: 500px;
  bottom: 4rem;
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  background-color: hsl(var(--clr-primary), .7);
  color: hsl(var(--clr-white));
  font-size: 2.2rem;
  border-radius: .2rem;
}

.article-detail-date {
  margin-top: .5rem;
  font-size: .9rem;
  font-weight: 200;
}

.article-detail-preface {
  color: #353333;
  font-size: 1.05rem;
  font-style: italic;
}

.article-detail-copy {
  width: 100%;
  max-width: 800px;
  padding: 3rem 0;
}

.article-detail-copy > p:last-of-type {
  margin-bottom: 6rem;
}

/* CONTACT SECTION */
.contact-section {
  position: relative;
  padding: 6rem 10rem;
  background-image: 
    linear-gradient(
    to bottom right,
    hsl(210, 25%, 94%),
    hsl(var(--clr-background-2)),
    hsl(var(--clr-background-2))
  );
  color: hsl(var(--clr-primary));
}

.contact-content {
  position: relative;
  display: flex;
  justify-content: space-around;
  border-radius: .2rem;
  background-color: hsl(var(--clr-secondary));
}

.contact-content-left,
.contact-content-right {
  width: 45%;
  padding: 3rem 2rem;
}

.contact-map-img {
  width: 100%;
  margin: 1.5rem 0 2rem;
  border-radius: .2rem;
}

.contact-copy {
  margin-bottom: 1rem;
}

.contact-copy.main-office {
  font-size: 1.4rem;
  font-weight: 400;
}

.contact-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: .2rem;
  margin-top: 2rem;
  font-weight: 400;
}

.contact-form input,
.contact-form textarea {
  font-family: var(--font-family-default);
  font-size: var(--fs-400);
  line-height: 1.2;
  color: hsl(var(--clr-primary));
}

.contact-form textarea {
  padding: 1rem 1rem;
}

.select-label {
  display: flex;
  flex-direction: column;
}

.contact-form select {
  font-family: var(--font-family-default);
  font-size: 1.1rem;
  color: hsl(var(--clr-primary));
  margin-top: 1rem;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.contact-form select::placeholder,
.contact-form input::placeholder,
.contact-form textarea::placeholder {
  font-family: var(--font-family-default);
  font-size: var(--fs-400);
  line-height: 1.2;
  color: hsl(var(--clr-primary));
  font-weight: 400;
}

.contact-form input:focus, 
.contact-form select:focus, 
.contact-form textarea:focus {
  outline: none;
} 

.contact-form input, 
.contact-form select {
  min-width: 100%;
  background-color: hsl(var(--clr-white));
  padding: .7rem 1rem;
  margin-bottom: 1.5rem;
  border: 1.5px solid hsl(var(--clr-white));
  border-radius: .2rem;
}

.contact-form .jobTitleField {
  min-width: 100%;
  background-color: hsl(var(--clr-white));
  padding: .81rem 1rem;
  margin-bottom: 1.5rem;
  border: 1.5px solid hsl(var(--clr-white));
  border-radius: .2rem;
}

.contact-form input.input-error,
.contact-form select.input-error,
.contact-form textarea.input-error,
.upload-btn-content .upload-btn.input-error
{
  border: 1.5px solid #d20c0c;
}

.mailing-list-input.input-error {
  border: 3px solid hsl(var(--clr-background-3));
}

.contact-form textarea {
  min-width: 100%;
  max-width: 100%;
  height: 227px;
  padding-top: .5rem;
  margin: 0 0 1rem;
  background-color: hsl(var(--clr-white));
  border: 1.5px solid hsl(var(--clr-white));
  border-radius: .2rem;
  resize: none;
}

.contact-btn {
  align-self: flex-start;
  background-color: hsl(var(--clr-primary));
  color: hsl(var(--clr-white));
  border: none;
  border-radius: .2rem;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all .25s ease-in-out;
}

.contact-btn:hover {
  align-self: flex-start;
  background-color: hsl(var(--clr-white));
  color: hsl(var(--clr-primary));
}

.thank-you-popup {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: calc(40% - 125px);
  left: calc(50% - 175px);
  width: 350px;
  height: 350px;
  padding: 1.5rem;
  background-color: hsl(var(--clr-accent), .4);
  border-radius: .2rem;
  text-align: center;
  background-color: #797979e5;
  box-shadow: 4px 4px 12px #999898;
  z-index: 10;
  -webkit-animation: .2s ease 0s normal forwards 1 fadein;
    animation: .2s ease 0s normal forwards 1 fadein;
}

@keyframes fadein{
  0% { opacity:0; }
  66% { opacity:0; }
  100% { opacity:1; }
}

@-webkit-keyframes fadein{
  0% { opacity:0; }
  66% { opacity:0; }
  100% { opacity:1; }
}

.checkmark-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .5rem 0 1rem;
  background-color: hsl(var(--clr-white));
  border-radius: 50%;
}

.thank-you-pop-checkmark {
  font-size: 4rem;
  color: hsl(var(--clr-primary));
}

.thank-you-pop-error {
  font-size: 4rem;
  color: var(--clr-brick);
}

.thank-you-pop-checkmark-background {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0%;
  background-color: hsl(var(--clr-white));
  border-radius: 50%;
}

.thank-you-copy.success {
  color:hsl(var(--clr-white));
  font-size: 2.5rem;
}

.thank-you-copy {
  display: inline-block;
  color:hsl(var(--clr-white));
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.3;
}

.required-field-note {
  display: flex;
  align-items: center;
  align-self: flex-start;
  font-size: .8rem;
  margin-bottom: 1rem;
}

.required-field-note span {
  color: #d20c0c;
  font-size: 1.2rem;
  margin-right: .3rem
}


/* FOOTER */

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  width: 100%;
  background-color: #5a5858;
  padding: 3rem 6rem 1rem;
  z-index: 20;
}

.footer-mobile-social {
  display: none;
}

.footer-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  z-index: 3;
}

.footer-content {
  width: 80%;
}

.footer-category {
  margin-bottom: .6rem;
  color: hsl(var(--clr-white));
  font-size: .9rem;
  font-weight: 200;
}

.footer-content ul > a {
  margin-bottom: .6rem;
  color: hsl(var(--clr-white));
  font-size: .9rem;
  font-weight: 200;
  margin-left: 1rem;
}

.footer-left,
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.footer-left-top,
.footer-left-middle,
.footer-left-bottom,
.footer-right-top,
.footer-right-middle {
  display: flex;
  flex-direction: column;
}

.footer-right-bottom {
  display: flex;
  justify-content: right;
}

.footer-right-bottom a img {
  height: 20px;
  width: 20px;
  margin: 5px 0 0 10px;
}

.footer-right {
  text-align: right;
}

.footer a {
  transition: all .1s ease-in-out;
}

.footer a:hover {
  font-weight: 300;
}

.footer-social-icons {
  font-size: 1.5rem;
  color: hsl(var(--clr-white));
  margin-left: .4rem;
  transition: all .2s ease-in-out;
}

.footer-social-icons:hover {
  scale: 1.1;
}

.copyright {
  color: hsl(var(--clr-white));
  font-size: .9rem;
  font-weight: 200;
}

/* STAFF PAGE */

.staff-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.staff-content {
  width: 100%;
  padding: 0 2rem;
}

.staff-page-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 0rem;
}

.staff-main-header {
  text-align: center;
}

.staff-main-header {
  align-self: center;
  max-width: 700px;
}

.staff-copy {
  max-width: 800px;
  line-height: 1.2;
  align-self: center;
}

.offices-container {
  position: relative;
  width: 100%;
  max-width: 1100px;
  border: 2.5px solid hsl(var(--clr-primary));
  border-radius: .2rem;
  background-color: hsl(var(--clr-white));
  box-shadow: 2px 2px 15px hsl(var(--clr-primary), .2);
  z-index: 10;
}

.office-content {
  width: 100%;
  animation: growDown 500ms ease-in-out;
  animation-fill-mode: forwards;
  transform-origin: top center;
  line-height: 1;
  /* margin: 0 1rem; */
}

@keyframes growDown {
  0% {
      transform: scaleY(0)
  }
  80% {
      transform: scaleY(1.1)
  }
  100% {
      transform: scaleY(1)
  }
}

.no-dropdown {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: hsl(var(--clr-primary));
  color: hsl(var(--clr-white));
}

.no-dropdown .office-header {
  font-weight: 400;
}

.office {
  padding: .5rem 1rem;
  border-bottom: 2.5px solid hsl(var(--clr-primary));
  width: 100%;
  font-weight: 300;
}

.office-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.office:last-child {
  border-bottom: none;
}

.office-name {
  font-size: 1.4rem;
}

.office-phone {
  margin: .5rem 0;
}

.staff-profiles .empty-office-message {
  padding: 3rem 0 4rem;
  font-size: 1.5rem;
}




.staff-profiles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fill, 1fr);
  width: 100%;
  flex-wrap: wrap;
  min-height: 150px;
  max-height: 470px;
  overflow-x: scroll;
  margin-bottom: 2rem;
}

.staff-profile {
  display: flex;
  justify-content: space-between;
  padding: .5rem;
  margin: 0 1rem 1rem 0;
}

.staff-profile-left {
  display: flex;
}

.staff-profile.full-width {
  grid-column: 1/-1;
  max-height: 391px;
}

.staff-info-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 190px;
  min-width: 190px;
  margin-left: 1rem;
}

.staff-img {
  height: 100%;
  width: 100%;
  max-width: 250px;
  object-fit: cover;
  border-radius: .2rem;
  box-shadow: 10px 10px 10px hsl(var(--clr-primary), .3);
}

.staff-firstname,
.staff-lastname {
  font-size: 1.8rem;
}

.staff-suffix-name {
  margin-top: .3rem;
}

.staff-title {
  max-width: 10rem;
  margin: .5rem 0;
}

.staff-email, 
.staff-phone {
  display: flex;
  align-items: center;
}

.staff-email {
  color: hsl(var(--clr-primary));
  font-size: .92rem;
  margin-top: auto;
  cursor: pointer;
}

.staff-email.small {
  font-size: .78rem;
  letter-spacing: -.5px;
}

.small .staff-email-icon {
  font-size: .92rem;
}

.staff-email-icon, 
.staff-phone-icon {
  margin-right: .7rem;
}

.staff-phone {
  margin-top: .5rem;
  color: hsl(var(--clr-primary));
  cursor: pointer;
}

.staff-bio {
  padding: 0 .5rem 0 1rem;
  overflow: scroll;
  line-height: 1.2;
  width: 55%;
}

.toggle-office-icon {
  font-size: 2.8rem;
  padding: .5rem;
  transform: rotate(0deg);
  transition: transform .3s linear;
}

.toggle-office-icon.open{
  transform: rotate(180deg);
  transition: transform .3s linear;
}

.show-bio-btn {
  padding: .6rem .3rem;
  margin-top: .5rem;
  background-color: hsl(var(--clr-primary));
  font-size: .9rem;
  text-align: center;
  color: hsl(var(--clr-white));
  border-radius: .2rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.show-bio-btn:hover {
  background-color: hsl(var(--clr-secondary));
  color: hsl(var(--clr-primary));
}

/* ABOUT & MISSION PAGE */

.mission-section {
  padding-bottom: 0;
}

.mission-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  padding: 2rem;
}

.about-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
}

.about-copy {
  max-width: 800px;
}

.about-copy-lg {
  font-size: 1.3rem;
}

.about-content-bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5rem;
  padding: 4rem 2rem 0;
  z-index: 1;
}

.about-logo {
  min-width: 180px;
  max-width: 20%;
  margin-right: 2rem;
}

.about-graphic-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-graphic-subhead {
  position: absolute;
  top: calc(50% - 120px);
  left: calc(40% - 73px);
  width: 250px;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
}

.about-circle-graphic {
  width: 500px;
  padding-bottom: 10rem;
}

@keyframes up-right {
  0% {
      transform: scale(1);
      opacity: .25
  }
  50% {
      transform: scale (1, 5);
      opacity: 1;
  }
  100% {
      transform: scale(1);
      opacity: .25;
  }
}

.mission-graphic-marker {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  opacity: .25;
}

.mission-graphic-marker-1 {
  position: absolute;
  top: 12rem;
  left: 3rem;
}

.mission-graphic-marker-2 {
  position: absolute;
  top: 12rem;
  right: 3rem;
}

.mission-graphic-marker-3 {
  position: absolute;
  bottom: 13rem;
}

.white {
  background-color: hsl(var(--clr-white));
  -webkit-animation: up-right 1s infinite;
  -moz-animation: up-right 1.5s infinite;
  -o-animation: up-right 1.5s infinite;
  animation: up-right 1.5s infinite;
}

.graphic-prongs {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  min-height: 125px;
  padding: 1rem;
  background-color: hsl(var(--clr-white));
  color: hsl(var(--clr-white));
  text-align: center;
  font-size: 1.4rem;
  font-weight: 300;
  border-radius: .2rem;
  box-shadow: 2px 2px 15px #fdfdfdb5;
}

.prong-1 {
  top: 4rem;
  left: -16rem;
}

.prong-2 {
  top: 3rem;
  left: 29rem;
}

.prong-3 {
  bottom: 3rem;
}

.hidden {
  display: none;
}

.mission-logo {
  position: absolute;
  bottom: 17.5rem;
  width: 230px;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
}

.mission-graphic-container {
  display: flex;
  justify-content: space-between;
  margin: 5rem 0;
  width: 100%;
  max-width: 1000px;
}

.mission-step:nth-child(2) {
  margin: 0 1rem;
}

.mission-step {
  width: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 310px;
  min-height: 180px;
  margin: 0rem;
  padding: 1.5rem;
  color: hsl(var(--clr-white));
  border-radius: .2rem;
}

.mission-header {
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 500;
  display: inline-block;
}

.mission-step-graphic {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: .5rem;
}

.mission-step-line {
  width: 95%;
  margin: .7rem 0;
  border: 1px solid hsl(var(--clr-primary));
}

.mission-step-icon {
  position: absolute;
  right: 0;
  color: hsl(var(--clr-primary));
}

.mission-step-icon.dot {
  font-size: .9rem;
}

.mission-step-copy {
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.1;
}

.mission-bottom-content {
  display: flex;
  align-items: center;
  max-width: 1000px;
}

.family-collage {
  width: 45%;
  height: auto;
}


/* Family Defense */

.programs-intro .copy {
  max-width: 650px;
}

.courthouse-img {
  width: 55%;
  min-width: 500px;
  border-radius: .2rem;
  float: left;
  margin-right: 2rem;
}

.prevention-intro {
  max-width: 1000px;
}

/* BOARD PAGE */

.board-content {
  max-width: 1000px;
  padding: 0 2rem;
}

.board-profile {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.board-profile:nth-of-type(even) {
  flex-direction: row-reverse;
}

.board-profile:nth-of-type(even) .board-info-container {
  padding: 0 3.5rem 0 0rem ;
}

.board-profile:last-of-type {
  margin-bottom: 6rem;
}

.board-img {
  max-height: 400px;
  object-fit: cover;
  border-radius: .2rem;
  box-shadow: 10px 10px 10px hsl(var(--clr-primary), .3);
}

.board-info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  padding: 0 1.5rem 0 3.5rem ;
}

.board-name-container > h1 {
  display: inline;
  font-size: 1.8rem;
  font-weight: 500;
}

.board-prefix-name,
.board-firstname,
.board-comma {
  margin-right: .3rem;
}

.board-title {
  margin-top: .5rem;
  font-size: 1.5rem;
  font-weight: 300;
}

.board-bio {
  margin-top: 1.2rem;
  line-height: 1.2;
}

.board-copy {
  font-size: 1.1rem;
  max-width: 710px;
  text-align: center;
}

/* FINANCIALS PAGE */

.financials-section {
  padding-bottom: 0;
}

.financials-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0rem 2rem;
  font-size: 1rem;
}

.financials-graphic-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  font-size: 1rem;
}

.financials-graphic {
  width: 100%;
  max-width: 700px;
}

.budget-graph {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
}

.financials-graphic-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.financials-content-bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  color: hsl(var(--clr-white));
  padding: 2rem;
  margin-top: 5rem;
  font-size: 1rem;
  font-weight: 300;
  z-index: 1;
}

.financials-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 1200px;
  min-height: 400px;
  margin: 0 auto 1rem;
  padding: 1rem 0;
  z-index: 1;
}

.two-column {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-auto-rows: 2rem;
  gap: .6rem 1rem;
}

.bottom-two-column {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 235px));
  grid-auto-rows: 2rem;
  gap: .6rem 1rem;
}

.two-column > * {
  margin: auto 0;
}

.three-column {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem 0;
}

.three-column > div {
  margin: 0 1rem 2rem;
  align-self: center;
}

.financials-graphic-container .financials-table {
  font-size: 1.2rem;
}

.financials-table-header {
  grid-column: 1 / span 2;
}

.financials-table-footer {
  text-transform: uppercase;
}

.financials-subhead {
  font-size: 1.1rem;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: .7px;
}

.financials-last-table-data {
  text-decoration: underline;
  text-underline-offset: 20px;
  text-decoration-thickness: .7px;
}

.financials-copy-bottom {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  justify-content: space-between;
  align-items: center;
}

.main-table-footer {
  align-self: flex-end;
  text-align: right;
  font-size: 1.4rem;
  border: .7px solid hsl(var(--clr-white));
  padding: 1rem;
}

.financials-table-footer-blue {
  padding-top: .3rem;
  font-weight: 500;
  border-top: .7px solid hsl(var(--clr-primary));
}

.financials-table-footer-white {
  padding-top: .3rem;
  font-weight: 500;
  border-top: .7px solid hsl(var(--clr-white));
}

.financials-downloads-container .financials-section-subhead {
    margin: 3rem 0 2rem;
}

.financials-downloads {
  margin-bottom: 1rem;
  font-weight: 200;
}

.financials-downloads a {
  font-weight: 400;
  padding-left: .3rem;
}

/* DONATE PAGE */

.donate-section {
  padding-bottom: 10rem;
}

.donate-content {
  display: flex;
  max-width: 1000px;
}

.donate-content > * {
  width: 50%;
}

.donate-copy-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
}

.donate-copy-container > * {
  margin-bottom: 1.5rem;
}

.donate-header {
  position: relative;
  align-self: flex-start;
  z-index: 1;
  font-size: var(--fs-800);
  font-weight: 600;
  min-width: 100px;
}

.donate-btn {
  width: 100%;
  background-color: hsl(var(--clr-primary));
  color: hsl(var(--clr-white));
  border: none;
  border-radius: .2rem;
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.donate-btn:hover {
  background-color: hsl(var(--clr-secondary));
  color: hsl(var(--clr-primary));
}

.donate-img-container {
  position: relative;
}

.donate-img-container-mobile {
  display: none;
}

.donate-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: .2rem;
  box-shadow: 10px 10px 10px hsl(var(--clr-primary), .2);
}

.donate-quote {
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  padding: 3rem 2rem;
  background-color: hsl(var(--clr-primary), .7);
  color: hsl(var(--clr-white));
  font-style: italic;
  font-size: 1.3rem;
  font-weight: 300;
  text-align: center;
  line-height: 1.2;
  border-bottom-left-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.donate-quote-left {
  position: absolute;
  left: 1rem;
  top: 1rem;
  font-size: 1.4rem;
}
.donate-quote-right {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  font-size: 1.4rem;
} 



/* CAREERS PAGE */

.career-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding-top: 10rem;
  padding-bottom: 0;
}

.career-content {
  position: relative;
  align-self: center;
  max-width: 900px;
}

.careers-copy {
  max-width: 1000px;
  line-height: 1.3;
  margin-bottom: 1rem;
}

.career-handshake {
  width: 45%;
  border-radius: .2rem;
  box-shadow: 10px 10px 10px hsl(var(--clr-primary), .2);
}

.career-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.career-link {
  display: flex;
  color: hsl(var(--clr-primary));
}

.career-title-link {
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: .4rem;
  margin-left: .3rem;
}

.career-detail-copy {
  width: 100%;
  max-width: 800px;
  padding: 0rem 1rem 3rem;
}

.careers-copy.internship {
  margin-bottom: 6rem;
}

.careers-mailing-list {
  width: 100%;
}

.career-apply-btn {
  align-self: flex-start;
  background-color: hsl(var(--clr-primary));
  color: hsl(var(--clr-white));
  border: none;
  border-radius: .2rem;
  padding: 1rem 2rem;
  margin-top: 2rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all .3s ease-in-out;
}
  
.career-apply-btn:hover {
  align-self: flex-start;
  background-color: hsl(var(--clr-secondary));
  color: hsl(var(--clr-primary));
}


/* INTERN APPLY FORM  */

.intern-apply-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  margin: 0;
}


/* APPLY FORM  */

.apply-form {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  margin: 0;
  background-color: hsl(var(--clr-white), .8);
  z-index: 100;
}

.apply-form-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  margin-top: 2rem;
  max-width: 500px;
  background-color: hsl(var(--clr-secondary));
  border-radius: .2rem;
}

.apply-form-content .form-row {
  width: 100%;
}

.apply-form textarea {
  height: 150px;
  margin-bottom: 1rem;
}

.apply-form:target .popup{
  animation: popup 0.7s;
}

@keyframes popup {
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.4);
  }
  60%{
    transform: scale(1.1);
  }
  70%{
    transform: scale(1.2);
  }
  80%{
    transform: scale(1);
  }
  90%{
    transform: scale(1.1);
  }
  100%{
    transform: scale(1);
  }
}

.apply-upload-field {
  display: none;
}

.apply-form-close-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: .75rem;
  right: .75rem;
  padding: .2rem;
  background-color: hsl(var(--clr-white));
  color: hsl(var(--clr-primary));
  border-radius: .2rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.apply-form-close-btn:hover {
  background-color: hsl(var(--clr-primary));
  color:hsl(var(--clr-white));
}

.upload-btn-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: .75rem;
}

.upload-btn-content {
  position: relative;
  width: 48%;
  padding-bottom: 1.1rem;
}

.upload-btn {
  display: block;
  width: 100%;
  background-color: #797979;
  color: hsl(var(--clr-white));
  padding: .5rem 1rem;
  font-size: .9rem;
  line-height: 1.1;
  text-align: center;
  border-radius: .2rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
  border: 1.5px solid #797979;
}

.upload-btn:hover {
  background-color: #6b6a6a;
}

.upload-file-container {
  position: absolute;
  left: 0;
  bottom: -.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5px;
  width: 100%;
  font-size: .65rem;
  color: #515151;
}

.upload-max-file-copy {
  padding: .2rem 0;
}

.resume-file-remove-btn,
.coverletter-file-remove-btn {
  padding: .2rem;
  cursor: pointer;
  background-color: hsl(var(--clr-white), .4);
  transition: all .2s ease-in-out;
}

.resume-file-remove-btn:hover,
.coverletter-file-remove-btn:hover {
  cursor: pointer;
  background-color: hsl(var(--clr-white));
}


/* IMPACT PAGE */
.impact-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 10rem;
  padding-bottom: 0;
}

.impact-copy {
  line-height: 1.3;
  margin: 0 0 1rem 0 ;
}

.impact-copy.system-change-list {
  list-style: disc;
  padding: 1rem 0;
}

.impact-content-container {
  position: relative;
  max-width: 1000px;
  z-index: 1;
}

.impact-top-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 8rem;
}

.impact.section-subhead {
  font-weight: 400;
  font-size: 1.5rem;
  margin: 0;
  max-width: 400px;
}

.impact-header {
  max-width: 500px;
}

.impact-header-copy {
  max-width: 600px;
  padding: 2rem;
  margin: 2rem 0 0 6rem;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.1;
  z-index: 1;
}

.impact-dad-img-container {
  display: flex;
  position: relative;
  align-items: center;
  margin-top: -2.5rem;
}

.dad-son-img {
  object-fit: cover;
  width: 100%;
  max-width: 600px;
  height: 300px;
  object-position: 0 100%;
  border-radius: .2rem;
}

.dad-son-quote {
  position: relative;
  padding: 1.5rem 3rem;
  background-color: hsl(var(--clr-primary), .7);
  color: hsl(var(--clr-white));
  margin: 0 -8rem 0 8rem;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.2;
  text-align: center;
  border-radius: .2rem;
}

.impact.quote-left,
.impact.quote-right {
  position: absolute;
  font-size: 1.7rem;
}

.impact.quote-left {
  top: .6rem;
  left: .6rem;
  z-index: 1;
}

.impact.quote-right {
  bottom: .6rem;
  right: .6rem;
  z-index: 1;
}

.impact-stats-container {
  align-self: flex-start;
  width: 100%;
  max-width: 1000px;
  padding: 0rem 0 3rem;
}

.impact-stats-content {
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
}

.impact-stat {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  height: 300px;
  padding: 1rem;
  background-color: hsl(var(--clr-primary), .7);
  border-radius: .2rem;
}

.impact-stat-copy {
  color: hsl(var(--clr-white));
  font-weight: 300;
  line-height: 1.05;
  text-align: center;
}

.impact-stat img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: .2rem;
  z-index: -1;
}

.impact-stat-asterisk {
  position: absolute;
  font-size: .8rem;
  padding-top: .4rem;
}

.impact-stat-disclaimer {
  font-size: .7rem;
  padding-top: .5rem;
}

.stat-1 {
  background-color: hsl(var(--clr-primary), .7);
}

.stat-2 {
  background-color: hsl(var(--clr-background-3), .8);
  margin: 0 1rem;
}

.stat-3 {
  background-color: hsl(var(--clr-stat), .8);
}

.stat-1 .line-1{
  font-size: 2.4rem;
}

.stat-1 .line-2 {
  font-size: 3rem;
  font-weight: 600;
}

.stat-1 .line-3 {
  font-size: 1.85rem;
}

.stat-2 .line-1{
  font-size: 2.7rem;
}

.stat-2 .line-2 {
  font-size: 2.4rem;
  font-weight: 600;
}

.stat-2 .line-3 {
  font-size: 2.3rem;
}

.stat-3 .line-1{
  font-size: 2.3rem;
}

.stat-3 .line-2 {
  font-size: 2.3rem;
  font-weight: 600;
}

.stat-3 .line-3 {
  font-size: 1.85rem;
}



.impact-stats-container li:nth-child(2) {
  background-color: hsl(var(--clr-primary));
  width: 100%;
}

.impact-stats-container li:nth-child(3) {
  background-color: hsl(var(--clr-background-3));
  width: 60%;
}

.impact-stats-container li:nth-child(4) {
  background-color: hsl(var(--clr-secondary));
  width: 75%;
}

.impact.quote-left,
.impact.quote-right {
  font-size: 1.5rem;
}

.impact-testimonials-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(20, auto);
  grid-template-rows: repeat(10, auto);
  grid-gap: 1.5rem;
  width: 100%;
  padding: 5rem 10rem 5rem;
  margin-top: 2rem;
  z-index: 2;
}

.impact-testimonials-container-mobile {
  display: none;
}

.impact-downloads-section {
  position: relative;
  width: 100%;
  padding-top: 5rem;
}

.testimonial {
  position: relative;
  border-radius: .2rem;
  z-index: 1;
}

.testimonial img {
  object-fit: cover;
  width: 100%;
  min-height: 100%;
  max-height: 300px;
  border-radius: .2rem;
}

.testimonial-quote {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: hsl(var(--clr-white), .7);
  color: var(--clr-burgundy);
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.2;
  height: 100%;
  text-align: center;
  border-radius: .2rem;
  z-index: 1;
}

.testimonial-1 {
  grid-column: 1 / 8;
  grid-row: 1 / 3;
}

.testimonial-2 {
  grid-column: 8 / 14;
  grid-row: 1 / 6;
}

.testimonial-3 {
  grid-column: 14 / 21;
  grid-row: 1 / 4;
}

.testimonial-4 {
  grid-column: 1 / 8;
  grid-row: 3 / 11;
}

.testimonial-5 {
  grid-column: 8 / 14;
  grid-row: 6 / 11;
}

.testimonial-6 {
  grid-column: 14 / 21;
  grid-row: 4 / 11;
}

.impact-mom-infant {
  object-fit: cover;
  width: 100%;
}

.impact-link {
  margin-bottom: .4rem;
}

.impact-middle-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3rem 2rem 5rem;
}

.impact-presentation-img {
  width: 50%;
  border-radius: .2rem;
  margin-bottom: 0;
}

.impact-bottom-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: hsl(var(--clr-white));
}

/* DIVERSITY SECTION */
.diversity-header {
  max-width: 800px;
  margin-bottom: 1.5rem;
}

.diversity-header-wide {
  max-width: 900px;
  margin-bottom: 1.5rem;
}


.diversity-subhead {
  margin: 1.5rem 0 1rem;
  font-size: 1.7rem;
  font-weight: 500;
  
}

.diversity-img {
  object-fit: cover;
  width: 500px;
  border-radius: .2rem;
  box-shadow: 10px 10px 10px hsl(var(--clr-primary), .2);
}

.diversity-committee-header {
  margin-top: 2rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 7px;
  line-height: 1.5;
}

.diversity-committee-list {
  margin: 1.2rem 0 0 1rem;
  line-height: 1.4;
}

.diversity-committee-list > li {
  font-size: 1.2rem;
  list-style-type: disc;
}


/* MAILING LIST  */

.mailing-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: hsl(var(--clr-white));
  border-bottom-left-radius: .3rem;
  border-top-left-radius: .3rem;
  margin: 4rem 0 5rem;
}

.mailing-list-copy {
  margin-bottom: 1rem;
  max-width: 450px;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
  text-align: center;
}

.mailing-list-form {
  display: grid;
  grid-template-columns: 3fr 1fr;
  width: 100%;
  max-width: 600px;
}

.mailing-list-input {
  position: relative;
  height: 50px;
  padding: 0 1rem;
  background-color: hsl(var(--clr-white));
  border: 3px solid hsl(var(--clr-white));
  border-bottom-left-radius: .2rem;
  border-top-left-radius: .2rem;
  font-size: 1.1rem;
  color: hsl(var(--clr-primary));
  z-index: 1;
}

.mailing-list-input::placeholder {
  font-family: var(--font-family-default);
  font-size: var(--fs-400);
  line-height: 1.2;
  color: hsl(var(--clr-primary));
  font-weight: 400;
}

.mailing-list-input:focus {
  outline: none;
}

.mailing-list-btn {
  height: 50px;
  padding: 0 .5rem;
  background-color: hsl(var(--clr-primary));
  color: hsl(var(--clr-white));
  border-bottom-right-radius: .3rem;
  border-top-right-radius: .3rem;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  transition: all .3s ease-in-out;
}

.mailing-list-btn:hover {
  background-color: hsl(var(--clr-secondary));
  color: hsl(var(--clr-primary));
}

.mailing-list-btn:focus {
  outline: none;
}


/* KNOW YOUR RIGHTS PAGE */

.kyrights-section {
  justify-content: center;
}

.kyrights-content {
  padding-bottom: 5rem;
}

.kyrights.section-subhead {
  margin-top: -1rem;
}



/* 404 PAGE */

.not-found-section {
  justify-content: center;
  text-align: center;
}

.not-found-content {
  padding-bottom: 5rem;
}

.not-found.section-header {
  margin: 0;
  font-size: 6rem;
}

.not-found.section-subhead {
  margin: 0;
  font-size: 2.2rem;
}

.not-found.copy {
  margin-top: .5rem;
  font-size: 1rem;
}

.not-found-btn {
  align-self: center;
  background-color: hsl(var(--clr-primary));
  color: hsl(var(--clr-white));
  border: none;
  border-radius: .2rem;
  padding: 1rem 2rem;
  margin-top: 3rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all .25s ease-in-out;
}
  
.not-found-btn:hover {
  background-color: hsl(var(--clr-white));
  color: hsl(var(--clr-primary));
}



@media screen and (max-width: 1300px) {

  .approach-content {
    padding: 2rem;
  }

  .contact-section {
    padding: 6rem 2rem;
  }
}


@media screen and (max-width: 1200px) {

  .approach-highlight {
    padding: 1rem 1rem;
    margin: 2rem 0 6rem;
  }

  .financials-graphic-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .impact-testimonials-container {
    padding: 4rem 2rem;
  }
}

@media screen and (max-width: 1100px) {

  .intro-header {
    left: 20%;
  }

  .intro-copy-container {
    left: 50%;
    top: 70%
  }

  .approach-copy-quote {
    font-size: 1.2rem;
    padding: 3rem;
  }
 
  .approach-content {
    width: 100%;
  }

  .approach-content-bottom {
    width: 100%;
  }

  .approach-copy-container {
    margin-left: 0rem;
  }

  .approach-family-img {
    width: 100%;
    max-width: 600px;
    margin-bottom: 1rem;
  }

  .rights-banner {
    font-size: 2rem;
    min-width: 100%;
  }

  /* ABOUT, MISSION, VISION PAGE */

  .about-circle-graphic {
    width: 80vw;
    max-width: 500px;
  }

  .white {
    background-color: hsl(var(--clr-white));
    -webkit-animation: up-right 1s infinite;
    -moz-animation: up-right 1.5s infinite;
    -o-animation: up-right 1.5s infinite;
    animation: up-right 1.5s infinite;
  }

  .graphic-prongs {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    min-height: 125px;
    padding: 1rem;
    background-color: hsl(var(--clr-white));
    color: hsl(var(--clr-white));
    text-align: center;
    font-size: 1.4rem;
    font-weight: 300;
    border-radius: .2rem;
    box-shadow: 2px 2px 15px #fdfdfdb5;
  }

  .prong-1 {
    top: 4rem;
    left: -16rem;
  }

  .prong-2 {
    top: 3rem;
    left: 29rem;
  }

  .prong-3 {
    bottom: 3rem;
  }

  .news-carousel {
    justify-content: center;
  }

  .contact-content-left,
  .contact-content-right {
    width: 50%;
    padding: 3rem 2rem;
  }

  .impact-bottom-section {
    padding: 2rem;
  }

   /* STAFF PAGE  */

  .offices-container {
    width: 100%;
  }

  .office {
    width: 100%;
    padding: .5rem;
  }

  .office-name {
    font-size: 1.2rem;
  }


  /* DONATE PAGE */

  .donate-section {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  /* PROGRAMS PAGE */

  .program-content-container {
    padding: 0 2rem;
  }


  /* ARTICLE SECTION  */

  .articles-content {
    padding: 0 2rem;
  }

  /* CAREER PAGE  */

  .career-content {
    padding: 0 2rem;
  }
}



@media screen and (max-width: 1000px) {

  /* MISSION PAGE  */

  .about-graphic-subhead {
    position: absolute;
    top: calc(34% - 120px);
    left: calc(39% - 73px);
  }

  .about-graphic-container {
    max-width: 500px;
    margin-bottom: 2rem;
  }

  .about-circle-graphic {
    padding-bottom: 2rem;
  }

  .graphic-prongs {
    position: relative;
    width: 100%;
    min-height: 125px;
    line-height: 1.2;
    margin-bottom: 1rem;
  }

  .prong-1 {
    top: auto;
    left: auto;
  }

  .prong-2 {
    top: auto;
    left: auto;
  }

  .prong-3 {
    bottom: auto;
  }

  .mission-graphic-marker-1 {
    position: absolute;
    top: 12rem;
    left: 3rem;
  }

  .mission-graphic-marker-2 {
    position: absolute;
    top: 12rem;
    right: 3rem;
  }

  .mission-graphic-marker-3 {
    position: absolute;
    bottom: 31rem;
    z-index: 1;
  }

  .mission-bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
  }
  
  .family-collage {
    width: 100%;
    height: auto;
  }

  .family-collage.img-float-right {
    margin: 0;
  }

  .impact-stat-disclaimer {
    padding-top: 0;
  }
  

   /* IMPACT PAGE */

  .impact-section {
    padding-top: 5rem;
  }

  .impact-header {
    max-width: 800px;
  }

  .impact-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0rem;
  }

  .impact-stats-content {
    flex-direction: column;
    align-items: center;
  }

  .impact-stat {
    width: 100%;
    margin-bottom: 1rem;
  }

  .impact-top-container {
    padding: 2rem 2rem .5rem;
  }

  .impact-dad-img-container {
    flex-direction: column-reverse;
    margin: 0;
  }

  .dad-son-quote {
    margin: 0;
    border-radius: 0;
  }

  .dad-son-img {
    max-width: 100%;
    height: auto;
    border-radius: 0;
  }

  .impact-stats-container {
    padding: 0;
  }

  .impact-presentation-img {
    width: 100%;
  }

  .impact-middle-section {
    padding: 0 2rem 2rem;
  }

  /* PREVENTION PAGE  */

  .first-call-videos-content {
    max-width: 100%;
    margin-bottom: 3rem;
  }

  .first-call-video {
    max-width: 100%;
  }

  .first-call-video .tab {
    width: 5.5rem;
    font-size: .77rem;
  }
  

  .first-call-video .tab-1 {
    right: 0;
  }
  
  .first-call-video .tab-2 {
    right: 5.6rem;
  }
  
  .first-call-video .tab-3 {
    right: 11.2rem;
  }

}


@media screen and (max-width: 900px) {

  .phone-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .phone-copy-container {
    margin: 1rem 0;
  }

  .phone-btn.mobile {
    display: inline-block;
  }

  .phone-btn.desktop {
    display: none;
  }

  .approach-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .approach-family-img {
    margin-bottom: 0;
  }

  .approach-copy-quote {
    bottom: 0;
    font-size: 1.2rem;
    padding: 3rem;
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
  }

  .diversity-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }

  .diversity-img {
    width: 100%;
    margin: 0;
    margin-bottom: 2rem;
  }

  .diversity-content > * {
    width: 100%;
  }

  .contact-btn {
    align-self: center;
    width: 100%;
  }

  .not-found-btn {
    align-self: center;
    width: 100%;
  }

  /* CAREER PAGE  */

  .career-handshake {
    width: 100%;
  }

  .career-handshake.img-float-right {
    margin-left: 0;
  }

  .mailing-list {
    padding: 0 2rem;
  }

  .mailing-list-copy {
    font-size: 1.2rem;
  }

  /* STAFF PAGE  */

  .office-content {
    margin: 0;
  }

  .staff-profiles {
    grid-template-columns: 1fr;
  }

  .staff-profile {
    flex-direction: column;
    max-height: 100%;
  }

  .staff-profile-left {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .staff-profile.full-width {
    grid-column: 1/-1;
    max-height: 100%;
  }
  
  .staff-info-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 1rem;
    text-align: right;
  }
  
  .staff-img {
    height: 100%;
    width: 100%;
    min-width: 250px;
    max-width: 330px;
    object-fit: cover;
    border-radius: .2rem;
    box-shadow: 10px 10px 10px hsl(var(--clr-primary), .3);
  }

  .staff-firstname,
  .staff-lastname {
    font-size: 2rem;
  }

  .staff-title {
    align-self: flex-end;
    font-size: 1.4rem;
    max-width: 100%;
  }

  .staff-email {
    align-self: flex-end;
    margin-top: .5rem;
  }

  .staff-email,
  .staff-phone {
    font-size: 1rem;
  }

  .staff-email.small {
    font-size: .8rem;
    letter-spacing: 0;
  }

  .show-bio-btn {
    padding: .8rem 1.5rem;
    font-size: 1.2rem;
  }

  .staff-bio {
    padding: 0;
    margin-top: 1rem;
    min-width: 100%;
    max-height: 175px;
    overflow-x: scroll;
  }



  /* FINANCIALS PAGE  */

  .two-column {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 235px));
    gap: .6rem 1rem;
  }

  .financials-content-bottom {
    padding: 2rem;
  }

  .financials-graphic-container {
    flex-direction: column;
  }

  .budget-graph {
    margin-bottom: 3rem;
  }

  .main-table-footer {
    align-self: flex-start;
    text-align: center;
    line-height: 1.2;
  }
}



@media screen and (max-width: 800px) {
  section {
    padding: 0rem;
  }

  .single-page-layout {
    padding-top: 6rem;
  }

  .intro-content-container,
  .financials-section,
  .board-section,
  .staff-page-layout,
  .career-layout,
  .article-section,
  .donate-section {
    padding-top: 8rem;
  }

  .back-btn {
   position: absolute;
   top: auto;
   bottom: 2rem;
  }

  .intro-section,
  .contact-section {
    padding: 2rem;
  }

  .quote-banner {
    width: 100%;
    min-height: 200px;
    padding: 1.5rem 2rem;
    font-size: 1.3rem;
    font-weight: 300;
    font-style: italic;
    text-align: center;
    line-height: 1.5;
    color: hsl(var(--clr-white));
  }
  
  .quote-banner span {
    padding-right: 0rem;
    color: hsl(var(--clr-primary));
    font-family: 'Taviraj', serif;
    font-size: 3.2rem;
    font-weight: 600;
    text-decoration:underline;
    text-underline-offset: 6px;
    text-decoration-thickness: 1.5px;
    line-height: 1;
  }

  .news-section {
    padding: 3rem 2rem 2rem;
  }

  .copy {
    max-width: 100%;
  }

  .intro-img {
    width: 100%;
    object-fit: cover;
  }

  .intro-header {
    left: 0;
    font-size: 3.8rem;
    text-shadow: 4px 4px 9px hsl(var(--clr-primary), 0.6);;
    margin-left: 2rem;
  }

  .intro-copy-container {
    display: flex;
    align-items: center;
    width: 100%;
    color: hsl(var(--clr-white));
    text-shadow: 4px 4px 9px #222222;
    top: 55%;
    left: 0;
  }

  .intro-line-graphic {
    display: none;
  }

  .intro-copy {
    margin-left: 2rem;
    max-width: 85vw;
    font-size: 1.2rem;
  }

  /* BOARD PAGE */

  .board-profile:last-of-type {
    margin-bottom: 3rem;
  }

  /* PROGRAMS PAGE  */

  .programs-section {
    padding: 2rem;
  }

  .program-container {
    flex-direction: column;
  }

  .program-container:nth-child(odd) {
    flex-direction: column;
  }

  .prevention-services-content {
    flex-direction: column;
  }

  .prevention-services-content > div:first-of-type {
    margin-right: 0;
  }

  .programs-img {
    width: 100%;
    height: 100%;
  }

  .corridor-img-container.img-float-right {
    margin-left: 0;
  }

  .program-1 .programs-copy,
  .program-2 .programs-copy,
  .program-3 .programs-copy,
  .program-4 .programs-copy
  .program-5 .programs-copy {
    padding: 1rem;
  }


  /* APPROACH PAGE */

  .approach-section {
    padding: 5rem 0 4rem;
  }

  .approach-team {
    position: relative;
    margin: 3rem 0;
  }

  
  /* RIGHTS BANNER HOME PAGE  */

  .rights-banner {
    flex-direction: column;
    margin: 1.5rem 0;
  }

  .rights-banner > * {
    margin: .3rem 0;
  }

  .rights-cta {
    padding: 1rem;
    font-size: 1.4rem;
    font-weight: 300;
    cursor: pointer;
  }


  /* NEWS HOME PAGE  */

  .news-carousel {
    margin: 2rem 0;
  }

  .carousel-item {
    padding: 1rem;
    justify-content: center;
    min-width: 100%;
  }

  .carousel-item-img {
    align-self: center;
    min-height: 50vw;
  }

  .news-footer {
    padding: 0rem;
  }

  .news-social-links {
    justify-content: flex-start;
    width: 100%;
  }

  .news-social-copy {
    max-width: 50%;
  }


  /* CONTACT SECTION HOME  */

  .contact-content {
    flex-direction: column;
  }

  .contact-content-left,
  .contact-content-right {
    width: 100%;
    padding: 1rem 2rem;
  }

  /* FOOTER MAIN  */

  .footer {
    padding: 3rem 4rem;
  }

  .footer-content {
    width: 100%;
    flex-direction: column;
  }

  .footer-content > * {
    align-self: flex-start;
  }

  .footer-content .footer-right a {
    margin-left: 0;
  }

  .footer-right {
    width: 100%;
    text-align: left;
  }

  .footer-right > * {
    margin: 0;
    line-height: 1.3;
  }

  .footer-right-bottom {
    display: none;
  }

  .footer-mobile-social {
    position: relative;
    display: block;
    margin-bottom: .5rem;
    z-index: 10;
  }

  /* STAFF PAGE  */

  .staff-img {
    min-width: auto;
  }


  
  /* BOARD PAGE  */

  .board-profile {
    flex-direction: column;
    max-width: 100%;
  }

  .board-info-container {
    padding: 0;
    margin-bottom: 2rem;
  }

  .board-profile:nth-of-type(even) {
    flex-direction: column;
  }

  .board-profile:nth-of-type(even) .board-info-container {
    padding: 0;
  }

  .board-img {
    max-height: initial;
    width: 100%;
    margin-bottom: 2rem;
  }


  /* ABOUT, MISSION, VISION PAGE */

  .mission-section {
    padding-top: 6rem;
  }

  .about-logo {
    padding-bottom: 2rem;
    margin: 0;
  }

  .about-content {
    flex-direction: column;
    justify-content: space-between;
    min-height: 400px;
  }

  .mission-graphic-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    margin: 3rem 0 4rem;
  }

  .about-content-bottom {
    margin-top: 2rem;
  }

  .mission-step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 220px;
  }

  .mission-step:nth-child(2) {
    margin: 2rem 0;
  }

  .mission-step-line {
    width: 98%;
  }


  .mission-graphic-marker-1 {
    position: absolute;
    top: 10rem;
    left: 4.5rem;
  }

  .mission-graphic-marker-2 {
    position: absolute;
    top: 10rem;
    right: 4.5rem;
  }

  .mission-graphic-marker-3 {
    position: absolute;
    bottom: 31rem;
    z-index: 1;
  }

  /* FINANCIALS */

  .financials-content {
    padding: 0 2rem;
  }

  .financials-graphic-container {
    align-items: flex-start;
  }

  .financials-graphic {
    margin: 0;
  }

  .financials-section-subhead {
    min-width: 300px;
  }

  .financials-graphic-container .financials-table {
    font-size: 1rem;
  }


  /* IMPACT PAGE */

  .impact-testimonials-container {
    display: none
  }

  .impact-testimonials-container-mobile {
    display: flex;
    flex-direction: column;
  }

  /* DONATE PAGE */

  .donate-content {
    flex-direction: column;
  }

  .donate-content > * {
    width: 100%;
  }

  .donate-copy-container {
    padding: 0;
  }

  .donate-img-container {
    display: none;
  }

  .donate-img-container-mobile {
    display: block;
  }

  /* KNOW YOUR RIGHTS PAGE  */

  .kyrights-section {
    justify-content: flex-start;
  }

  .kyrights-section .back-btn {
    bottom: 30%;
    margin-top: 1rem;
  }

  .kyrights-content {
    align-self: flex-start;
    padding: 2rem;
  }

  /* NEWS ARTICLES PAGE  */

  .article-container {
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    padding: 1rem;
    margin-bottom: 1.5rem;
  }

  .article-container .article-img {
    position: relative;
    width: 100%;
    height: 350px;
  }

  .article-copy {
    margin: 1rem 0;
    height: 100%;
  }

  .article-body {
    max-width: 100%;
  }

  .article-readmore {
    margin-top: 1rem;
  }

  .article-video {
    width: 100%;
    height: auto;
  }

  .career-apply-btn {
    width: 100%;
  }

  /* NEWS INDIVIDUAL ARTICLE PAGE*/

  .article-detail-content {
    flex-direction: column-reverse;
    width: 100%;
    max-width: 100%;
    margin-top: 2rem;
    box-shadow: 10px 10px 10px hsl(var(--clr-primary), .3);
  }

  .article-detail-content img {
    margin-left: auto;
    height: auto;
    width: 100%;
    object-fit: cover;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
  }

  .article-detail-header {
    position: relative;
    min-width: auto;
    max-width: 100%;
    width: 100%;
    bottom: 0;
    margin-bottom: 0;
    font-size: 2rem;
    line-height: 1.1;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .second-article-image {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  
  /* STAFF PAGE  */

  .staff-profile {
    flex-direction: column;
    max-height: 100%;
    padding-right: 0;
    padding-left: 1rem;
  }

  .staff-profile-left {
    flex-direction: column;
  }
  
  .staff-profile.full-width {
    grid-column: 1/-1;
    max-height: 100%;
  }
  
  .staff-info-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-top: 1.5rem;
    text-align: left;
  }
  
  .staff-img {
    max-width: 100%;
  }

  .staff-name-container {
    display: flex;
  }

  .staff-lastname {
    margin-left: .5rem;
  }

  .staff-title {
    align-self: flex-start;
  }

  .staff-email {
    align-self: flex-start;
    font-size: 1.2rem;
  }

  .staff-email.small {
    font-size: 1.2rem;
  }

  .staff-bio {
    font-size: 1rem;
  }

}






@media screen and (max-width: 500px) {

  .single-page-layout {
    padding-top: 6rem;
  }

  .quote-banner {
    line-height: 1.4;
  }

  .quote-banner span {
    font-size: 2.5rem;
  }

  .approach-section {
    padding: 3rem 0rem;
  }

  .approach-copy-quote {
    display: none;
  }

  .approach-copy-container-mobile {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 1 / -1;
    grid-row: 1 / 4;
    padding: 1.5rem;
    background-color: hsl(var(--clr-white), .9);
    color: var(--clr-pink);
    font-size: 1.4rem;
    font-weight: 300;
    border-radius: .2rem;
    width: 100%;
  }

  .approach-copy-mobile {
    text-align: center;
    padding: 2rem;
  }

  .carousel-items-container {
    width: 100%;
    margin: 0rem 0rem 1rem 0;
  }

  .carousel-arrow {
    position: absolute;
    border-radius: 0;
    z-index: 1;
    color: hsl(var(--clr-white), .8);
  }

  .carousel-arrow:hover {
    color: hsl(var(--clr-white));
  }

  .left-arrow {
    top: 20rem;
    left: 2.5rem;
  }

  .right-arrow {
    top: 20rem;
    right: 2.5rem;
  }

  .carousel-item-img {
    width: 100%;
    height: 20vw;
    object-fit: cover;
    box-shadow: none;
  }

  .carousel-item {
    justify-content: flex-start;
    width: 100%;
    padding: 0;
  }

  .carousel-item-readmore {
    margin-top: 4rem;
  }

  .staff-firstname,
  .staff-lastname {
    font-weight: 400;
    font-size: 1.3rem;
  }

  .staff-title {
    font-size: 1.2rem;
  }

  .staff-email {
    align-self: flex-start;
    font-size: .95rem;
  }

  .staff-email.small {
    font-size: .95rem;
  }

  .donate-img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .donate-quote {
    position: relative;
  }

  /* NEWS INDIVIDUAL ARTICLE PAGE*/

  .article-body {
    font-size: 1.1rem;
  }

  .article-detail-preface {
    color: #353333;
    font-size: 1.05rem;
    font-style: italic;
  }

  .article-detail-copy {
    padding: 3rem 0;
    margin-bottom: 4rem;
  }

  /* MISSION PAGE  */

  .about-graphic-subhead {
    position: absolute;
    top: calc(29% - 120px);
    left: calc(40% - 55PX);
    width: 175px;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
  }

  .mission-graphic-marker-1 {
    position: absolute;
    top: 5rem;
    left: 3rem;
  }

  .mission-graphic-marker-2 {
    position: absolute;
    top: 5rem;
    right: 3rem;
  }
  
  .mission-graphic-marker-3 {
    position: absolute;
    top: 16.5rem;
  }

  /* CAREERS PAGE  */

  .career-title-link {
    line-height: 1.4;
  }

}



