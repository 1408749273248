/* SECTION GRAPHICS PLACEMENT */


.section-graphic {
  position: absolute;
  z-index: -100;
  opacity: .3;
}


.intro-right {
  right: 0;
  top: 0;
  height: 120%;
  z-index: 0;
}

.logo-watermark {
  right: -12rem;
  top: -8rem;
  height: 60%;
  opacity: .8;
}

.intro-bottom {
  bottom: 0;
  height: 95%;
  z-index: 2;
}

.approach-tile-graphic {
  position: absolute;
  z-index: 1;
}

.approach-right {
  top: 7rem;
  right: 0;
  height: 40rem;
  z-index: -10;
}

.approach-left {
  bottom: rem;
  left: 0;
  height: 45rem;
  z-index: -10;
}

.programs-graphic {
  top: 0;
  right: -4rem;
  height: 110%;
  opacity: .3;
  z-index: 1;
}

.programs-top {
  top: 0;
  right: -10rem;
  height: 35rem;
}

.programs-bottom {
  right: 0;
  bottom: -15rem;
  height: 55rem;
}

.programs-left {
  height: 40rem;
  top: 5%;
  left: 0rem;
  z-index: -1;
}

.board-top {
  top: 0;
  right: -10rem;
  height: 35rem;
}

.board-left {
  height: 40rem;
  top: 25%;
  left: 0rem;
  z-index: -1;
}

.impact-graphic-left {
  height: 42rem;
  top: 42%;
  left: 0rem;
  z-index: -1;
}

.programs-right-bottom {
  bottom: -5rem;
  right: 0rem;
  height: 40rem;
}

.approach-tile-1 {
  top: -1rem;
  right: 0;
  height: 20rem;
}

.approach-tile-2 {
  top: 1rem;
  right: 1rem;
  height: 14rem;
}

.approach-tile-3 {
  top: 1rem;
  left: 0rem;
  height: 14rem;
}

.phone-graphic-home {
  position: absolute;
  top: 1rem;
  left: 10%;
  height: 45rem;
  z-index: -1;
}

.news-graphic-home {
  position: absolute;
  bottom: 0;
  right: -5rem;
  width: 90rem;
  z-index: -1;
}

.contact-graphic-left {
  left: 0;
  top: 0;
  height: 80rem;
  z-index: 0;
}

.contact-graphic-right {
  right: 0;
  top: 0;
  height: 80rem;
  z-index: 0;
}

.footer-graphic-left {
  bottom: 0;
  left: 0;
  height: 50%;
  z-index: 0;
}

.footer-graphic-right {
  bottom: 0;
  right: 0;
  height: 50%;
  z-index: 0;
}


/* SINGLE PAGE GRAPHICS PLACEMENT */

.single-page-graphic {
  position: absolute;
  z-index: -1;
  opacity: .2;
}

.single-page-top {
  top: 0;
  right: -10rem;
  height: 35rem;
}

.single-page-middle {
  top: 40%;
  left: 0;
  height: 45rem;
}

.single-page-bottom {
  position: absolute;
  right: 0;
  bottom: -15rem;
  height: 45rem;
}

.single-page-bottom-lighter {
  right: 0;
  bottom: -15rem;
  height: 45rem;
  opacity: .1;
}

.staff-graphic-left {
  bottom: 0rem;
  left: -5rem;
  height: 35rem;
}

.staff-graphic-right {
  top: 20rem;
  right: -5rem;
  height: 37rem;
}

.careers-middle {
  z-index: 0;
}

@media screen and (max-width: 800px) {

  .intro-right {
    z-index: 1;
  }


  .contact-graphic-left {
    left: 0;
    top: 30%;
    height: 90rem;
    z-index: 0;
  }
  
  .contact-graphic-right {
    right: -20rem;
    top: -2rem;
    height: 80rem;
    z-index: 0;
  }

  .financials-top {
    display: none;
  }
}